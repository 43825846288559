import { UUID, isUuid } from "@screencloud/uuid";
import { set, get, uniq, groupBy, map, flatMap } from "lodash";

import { EmbedStatus } from "src/components/ShareModal";
import { DEFAULT_GLOBAL_DURATIONS, RefType } from "src/constants/constants";
import apolloClient from "src/state/apolloClient";
import {
  AvailableLayoutsForSizeDocument,
  AvailableLayoutsForSizeQuery,
  AvailableLayoutsForSizeQueryVariables,
  Channel,
  CreateOnboardingChannelDocument,
  CreateOnboardingChannelMutation,
  CreateOnboardingChannelMutationVariables,
  Scalars,
  SetStartChannelDocument,
  SetStartChannelMutation,
  SetStartChannelMutationVariables,
  UpdateChannelEmbedDocument,
  UpdateChannelEmbedMutation,
  UpdateChannelEmbedMutationVariables,
} from "src/types.g";
import {
  createFiles,
  createFolder,
  getFolderByIdAndName,
  getFolders,
  updateFiles,
} from "./file";
import {
  ChannelContent,
  ChannelMeta,
  Channels,
  Zone,
  ZoneContentList,
  channelWithMeta,
} from "../config/channels";
import { installAppAndAppInstances } from "./app";
import { BackgroundImages } from "../config/media";
import { createCanvases } from "./canvas";
import { createPlaylists } from "./playlist";
import { createTheme, updateChannelTheme } from "./theme";
import { AppInfo } from "../config/apps";
import { BrandInfo, queryBrandInfo } from "./brand";
import { isRootFolder } from "src/helpers/folderHelper";

type ContentRefObject = {
  id: UUID;
  type: RefType;
  name: string;
};
type Content = {
  appInstances: ContentRefObject[];
  images: ContentRefObject[];
  canvases: ContentRefObject[];
  playlists: ContentRefObject[];
};

const createChannel = async (
  zones: Scalars["JSON"],
  coverData: UUID | Scalars["JSON"],
  spaceId: UUID,
  channelName: string,
  layoutId: UUID
) => {
  const channelCover = isUuid(coverData)
    ? {
        coverData: {
          color: { bottom: "", name: "", top: "" },
        },
        coverImageId: coverData,
      }
    : {
        coverData: {
          color: coverData,
        },
        coverImageId: null,
      };
  const variables: CreateOnboardingChannelMutationVariables = {
    input: {
      autoPublish: true,
      content: {
        props: DEFAULT_GLOBAL_DURATIONS,
        zones,
      },
      layoutId,
      name: channelName,
      spaceId,
      ...channelCover,
    },
  };
  const { data } = await apolloClient.mutate<
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables
  >({
    mutation: CreateOnboardingChannelDocument,
    variables,
  });
  return data?.createChannel?.channel;
};

const updateEmbedChannel = async ({
  isEmbedEnabled,
  isEmbedPublic,
  channelId,
}: EmbedStatus & { channelId: UUID }) => {
  const variables: UpdateChannelEmbedMutationVariables = {
    input: {
      channelId,
      isEmbedEnabled,
      isEmbedPublic,
    },
  };

  const { data } = await apolloClient.mutate<
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables
  >({
    mutation: UpdateChannelEmbedDocument,
    variables,
  });
  return data?.updateChannelEmbed?.channel;
};

const updateStartChannel = async (channelId: UUID) => {
  const variables: SetStartChannelMutationVariables = {
    input: {
      channelId,
    },
  };

  const { data } = await apolloClient.mutate<
    SetStartChannelMutation,
    SetStartChannelMutationVariables
  >({
    mutation: SetStartChannelDocument,
    variables,
  });

  return data?.setStartChannel?.org;
};

const layoutData = async (spaceId: UUID) => {
  const variables: AvailableLayoutsForSizeQueryVariables = {
    spaceId,
    height: 1080,
    width: 1920,
  };
  const { data } = await apolloClient.query<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >({
    query: AvailableLayoutsForSizeDocument,
    variables,
  });
  return data?.availableLayoutsForSize?.nodes ?? [];
};

const getLayout = async (spaceId: UUID, layoutName: string) => {
  const layouts = await layoutData(spaceId);
  const channelLayout =
    layouts.find((layout) => layout.name === layoutName) || layouts[0];
  return channelLayout;
};

// Example:
// Input appsList:
// [
//   { appName: "AppA", appInstancesName: ["Instance1", "Instance2"] },
//   { appName: "AppB", appInstancesName: ["Instance3"] },
//   { appName: "AppA", appInstancesName: ["Instance1", "Instance4"] },
//   { appName: "AppB", appInstancesName: ["Instance3", "Instance6"] },
// ]
// Output appsList:
// [
//   { appName: "AppA", appInstancesName: ["Instance1", "Instance2", "Instance4"] },
//   { appName: "AppB", appInstancesName: ["Instance3", "Instance6"] },
// ]
export const makeAppsListUnique = (appsList: AppInfo[]): AppInfo[] => {
  const groupedApps = groupBy(appsList, "appName");

  const uniqueApps = map(groupedApps, (appGroup) => {
    const mergedAppInstancesName = uniq(flatMap(appGroup, "appInstancesName"));
    return {
      appName: appGroup[0].appName,
      appInstancesName: mergedAppInstancesName,
    };
  });

  return uniqueApps;
};

export const prepareChannelContent = ({
  totalZones,
  channelContents,
}: {
  totalZones: number;
  channelContents: { [key: string]: ChannelContent };
}) => {
  const allContent: ChannelContent = {
    apps: [],
    images: [],
    canvases: [],
    playlists: [],
  };

  for (let i = 0; i < totalZones; i++) {
    const zoneId = getZoneId(i + 1);
    const zoneContent = channelContents[zoneId];

    // Combine the playlists, apps, images, and canvases from the current zone with the allContent object
    allContent.playlists.push(...zoneContent.playlists);
    allContent.apps.push(...zoneContent.apps);
    allContent.images.push(...zoneContent.images);
    allContent.canvases.push(...zoneContent.canvases);
  }

  // Deduplicate the apps, playlists, images, and canvases arrays to remove any duplicates
  allContent.apps = makeAppsListUnique(allContent.apps);
  allContent.playlists = uniq(allContent.playlists);
  allContent.images = uniq(allContent.images);
  allContent.canvases = uniq(allContent.canvases);

  return allContent;
};

const createChannelContent = async ({
  content,
  spaceId,
  orgName,
  brandInfo,
  folderId,
  countryCode,
}: {
  content: ChannelContent;
  spaceId: UUID;
  orgName: string;
  brandInfo: BrandInfo | undefined;
  countryCode: string;
  folderId?: UUID;
}): Promise<Content> => {
  const filesConfig = content.images.map((image) => {
    return BackgroundImages[image];
  });

  const toContent = (item) => ({
    id: item.id,
    name: item.name,
    type: item.type,
  });

  const [
    installedAppIntances,
    installedImages,
    installedCanvases,
    installedPlaylists,
  ] = await Promise.all([
    content.apps.length > 0
      ? installAppAndAppInstances(content.apps, spaceId, countryCode)
      : null,
    content.images.length > 0
      ? createFiles({
          spaceId,
          folderId,
          files: filesConfig,
        })
      : null,
    content.canvases.length > 0
      ? createCanvases({
          canvases: content.canvases,
          spaceId,
          orgName,
          brandInfo,
        })
      : null,
    content.playlists.length > 0
      ? createPlaylists({
          playlistNames: content.playlists,
          spaceId,
          orgName,
          brandInfo,
          countryCode,
          rootFolderId: folderId,
        })
      : null,
  ]);

  const result: Content = {
    appInstances: installedAppIntances?.map(toContent) ?? [],
    images: installedImages?.map(toContent) ?? [],
    canvases: installedCanvases?.map(toContent) ?? [],
    playlists: installedPlaylists?.map(toContent) ?? [],
  };
  return result;
};

const getZoneId = (index: number) => {
  return `zone${index}`;
};

export const setNewContentId = ({
  zoneContent,
  zoneId,
  match,
}: {
  zoneContent: Record<string, unknown>;
  zoneId: string;
  match: ContentRefObject | undefined;
}) => {
  const zoneContentList = get(zoneContent, `${zoneId}.list`) as Zone["list"];
  // Find the index of the item in the zoneContentList that matches the provided match's name (match?.name).
  const indexToReplace = zoneContentList.findIndex(
    (x) => x.content._ref.id === match?.name
  );

  if (indexToReplace > -1) {
    set(
      zoneContent,
      `${zoneId}.list[${indexToReplace}].content._ref.id`,
      match?.id
    );
  }

  return zoneContent;
};

export const getMatchContentItem = (
  zoneContent: ZoneContentList,
  newContent: Content
) => {
  let match: ContentRefObject | undefined;

  if (zoneContent.content._ref.type === RefType.APP) {
    match = newContent.appInstances.find(
      (appInstance) => appInstance.name === zoneContent.content._ref.id
    );
    if (!match) {
      // If there is no matching app instance, check for a matching canvas in the newContent
      match = newContent.canvases.find(
        (canvas) => canvas.name === zoneContent.content._ref.id
      );
    }
  } else if (zoneContent.content._ref.type === RefType.FILE) {
    match = newContent.images.find(
      (image) => image.name === zoneContent.content._ref.id
    );
  } else if (zoneContent.content._ref.type === RefType.PLAYLIST) {
    match = newContent.playlists.find(
      (playlist) => playlist.name === zoneContent.content._ref.id
    );
  }
  return match;
};

const updateZoneContentWithNewContent = (
  channelMeta: ChannelMeta,
  newContent: Content
) => {
  const totalZones = Object.keys(channelMeta.zones).length;

  for (let index = 0; index < totalZones; index++) {
    const zoneId = getZoneId(index + 1);
    const zoneContent = channelMeta.zones;
    const zoneContentList = get(zoneContent, `${zoneId}.list`) as Zone["list"];
    zoneContentList.forEach((item: ZoneContentList) => {
      const match = getMatchContentItem(item, newContent);

      setNewContentId({
        zoneContent,
        zoneId,
        match,
      });
    });
  }
};

export const removeInvalidUUIDs = (channelMeta: ChannelMeta): ChannelMeta => {
  const newChannelMeta: ChannelMeta = { ...channelMeta };
  const totalZones = Object.keys(newChannelMeta.zones).length;

  for (let index = 0; index < totalZones; index++) {
    const zoneId = getZoneId(index + 1);
    const zoneContent = newChannelMeta.zones;
    // Filter the 'list' array of the current zone to remove items with invalid UUIDs.
    (zoneContent[zoneId] as Zone).list = (zoneContent[
      zoneId
    ] as Zone).list.filter((item) => {
      return !item.content?._ref?.id || isUuid(item.content._ref.id);
    });
  }

  return newChannelMeta;
};

interface CreateDemoChannelParams {
  spaceId: UUID;
  channelName: Channels;
  orgName: string;
  domainName: string;
  countryCode: string;
  folderId: UUID;
}

export const createDemoChannel = async ({
  spaceId,
  channelName,
  orgName,
  domainName,
  countryCode,
  folderId,
}: CreateDemoChannelParams): Promise<Channel> => {
  const brandInfo = await queryBrandInfo(domainName);

  const currentBackgroundFolder = await getFolderByIdAndName(
    "Backgrounds",
    folderId
  );

  // Step 1: Get channel metadata from ChannelWithMeta based on channelName.
  const channelMeta = channelWithMeta({ channelName, orgName, brandInfo });

  let channelCoverImageId;

  if (currentBackgroundFolder) {
    channelCoverImageId = currentBackgroundFolder?.filesByFolderId?.nodes?.find(
      (file) => file.name === channelMeta.coverImage.name
    )?.id;
  }

  if (!currentBackgroundFolder) {
    // Step 2: Create a cover file for the channel using the cover image specified in metadata + content for the channel.
    channelCoverImageId = await handleCoverFileCreation(spaceId, channelMeta);
    await handleChannelContentCreation(
      spaceId,
      orgName,
      brandInfo,
      countryCode,
      folderId,
      channelMeta
    );
  }

  // Step 3: Remove any invalid UUIDs from the channel metadata.
  const newChannelMeta = removeInvalidUUIDs(channelMeta);

  // Step 4: Get the layout for the channel layout specified in metadata.
  const channelLayout = await getLayout(spaceId, channelMeta.layoutName);

  // Step 5: create folder for background images
  await handleBackgroundFolderCreation(
    spaceId,
    folderId,
    newChannelMeta,
    channelLayout,
    Boolean(currentBackgroundFolder)
  );

  // Step 6: Create the channel itself with the specified layout, cover file, and other information.
  const channel = await createChannelWithMeta(
    spaceId,
    newChannelMeta,
    channelMeta,
    channelCoverImageId,
    channelLayout.id
  );

  if (channel) {
    await applyChannelTheme(channel.id, channelMeta.theme);
    await enableChannelEmbedding(channel.id);
    await setStartChannel(channel.id);
  }

  return channel as Channel;
};

const handleCoverFileCreation = async (
  spaceId: UUID,
  channelMeta: ChannelMeta
) => {
  const [coverChannelFile] = await createFiles({
    spaceId,
    files: [channelMeta.coverImage],
  });
  return coverChannelFile.id;
};

const handleChannelContentCreation = async (
  spaceId: UUID,
  orgName: string,
  brandInfo: any,
  countryCode: string,
  folderId: UUID,
  channelMeta: ChannelMeta
) => {
  const totalZones = Object.keys(channelMeta.zones).length;
  const allContent = prepareChannelContent({
    totalZones,
    channelContents: channelMeta.content,
  });

  const allCreatedContent = await createChannelContent({
    content: allContent as ChannelContent,
    spaceId,
    orgName,
    brandInfo,
    countryCode,
    folderId,
  });

  updateZoneContentWithNewContent(channelMeta, allCreatedContent);
};

const handleBackgroundFolderCreation = async (
  spaceId: UUID,
  folderId: UUID,
  newChannelMeta: ChannelMeta,
  channelLayout: any,
  hasCurrentBackgroundFolder: boolean
) => {
  const backgroundZone = channelLayout.config.zones.find(
    (zone) => (zone.name as string).toLowerCase() === "background"
  );

  if (backgroundZone) {
    const backgroundFileIds = (newChannelMeta.zones[
      backgroundZone.id
    ] as any).list
      .map(
        (item) =>
          item.content._ref.type === RefType.FILE && item.content._ref.id
      )
      .filter(Boolean);

    if (backgroundFileIds.length > 0) {
      const existFolders = await getFolders(folderId);

      let inputs = [];

      if (existFolders && !isRootFolder(existFolders)) {
        inputs = backgroundFileIds.map((id) => ({
          fileId: id,
          folderId: existFolders.id,
        }));
      } else {
        if (!hasCurrentBackgroundFolder) {
          const folder = await createFolder({ spaceId, parentId: folderId });
          inputs = backgroundFileIds.map((id) => ({
            fileId: id,
            folderId: folder.id,
          }));
        }
      }

      await updateFiles(inputs);
    }
  }
};

const createChannelWithMeta = async (
  spaceId: UUID,
  newChannelMeta: any,
  channelMeta: any,
  coverChannelFileId: string,
  layoutId: UUID
) => {
  return createChannel(
    newChannelMeta.zones,
    coverChannelFileId,
    spaceId,
    channelMeta.name,
    layoutId
  );
};

const applyChannelTheme = async (channelId: UUID, theme: any) => {
  const createdTheme = await createTheme(theme);
  await updateChannelTheme(channelId, createdTheme?.id);
};

const enableChannelEmbedding = async (channelId: UUID) => {
  await updateEmbedChannel({
    isEmbedEnabled: true,
    isEmbedPublic: true,
    channelId,
  });
};

const setStartChannel = async (channelId: UUID) => {
  await updateStartChannel(channelId);
};
